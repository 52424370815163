import React from 'react'
import Headline from '../components/Headline'
import IntroGradient from '../components/IntroGradient'
import LogoFixed from '../components/LogoFixed'
import Menu from '../components/Menu'
import Seo from '../components/Seo'

const Services = () => {
  return (
    <>
      <Seo title='Usługi ' desc='' url='/services/' />
      <Menu />
      <LogoFixed />
      <IntroGradient />
      <Headline back={'Usługi'}>
        <h1>
          Zbuduj <mark>marketing</mark>ową przewagę! <br />
          Sprawdź usługi.
        </h1>
      </Headline>
    </>
  )
}

export default Services
